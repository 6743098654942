const Header = () => {
  return (
    <div className="header header-clear unselectable header-animated px-0 px-2-md">
      <div className="header-brand">
        <div className="nav-item no-hover">
          <h6 className="tracking-tight">Kyle Salstrom</h6>
        </div>
        <div className="nav-item nav-btn has-sub" id="header-btn">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="header-nav" id="header-menu">
        <div className="nav-right text-sm">
          <div className="nav-item">
            <a href="https://www.linkedin.com/in/kylesalstrom/">LinkedIn</a>
          </div>
          <div className="nav-item">
            <a href="https://github.com/kylesalstrom">GitHub</a>
          </div>
          <div className="nav-item">
            <a href="https://www.instagram.com/kylesalstrom/">Instagram</a>
          </div>
          {/* <div class="nav-item">
            <a href="#">About</a>
          </div>
          <div class="nav-item">
            <a href="#">Contact</a>
          </div> */}
          <div className="nav-item">
            <a
              className="btn btn-black btn--sm py-0"
              href="https://www.develophants.com"
            >
              View Work
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
