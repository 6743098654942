import Header from './Header.js';
import Stats from './Stats';
import ContactMe from './ContactMe';
import Landing from './Landing';

const App = () => {
  return (
    <div className="bg-gray-100 min-h-100p">
      <Header/>
      <Landing/>
      <Stats/>
      {/* <ContactMe/> */}
      <div className="space"></div>
    </div>
  );
}

export default App;