import computer_icon from '../images/desktop-computer-icon.png';

const Stats = () => {
  return (
    <section className="mb-12">
      <div className="content px-2 px-12-lg px-24-xl">
        {/* <h2>My Skills</h2> */}
        <div className="u-flex u-items-center u-gap-4 u-flex-column u-gap-8-lg u-flex-row-md">
          <div>
            <div className="frame bg-white">
              <div className="frame__header u-text-center">
                <div>
                  <figure className="avatar avatar--lg bg-transparent u-shadow-lg">
                    <img className="p-1" src={computer_icon} alt="Desktop Computer Icon"/>
                  </figure>
                  <div>
                    <h6 className="frame__title">Kyle Salstrom</h6>
                    <div className="frame__subtitle">Software Developer</div>
                  </div>
                </div>
              </div>
              <div className="frame__body">
                <div className="px-2 py-2 bg-black u-round-sm u-shadow-lg">
                  <div className="u-text-center text-white">
                    <div className="u-flex u-justify-center u-gap-3">
                      <div>
                        <h6 className="mb-0">15</h6>
                        <p className="uppercase font-bold text-sm">Years</p>
                      </div>
                      <div>
                        <h6 className="mb-0">100%</h6>
                        <p className="uppercase font-bold text-sm">On Time</p>
                      </div>
                      <div>
                        <h6 className="mb-0">0</h6>
                        <p className="uppercase font-bold text-sm">Bugs</p>
                      </div>
                    </div>
                    <p>Full stack developer with the experience, aptitude, and curiosity required to make excellent software. Personable, charismatic, influential, and respectful communicator. Skilled with agile software development, design patterns, algorithms, and data structures. Passionate about teaching and learning.</p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <a href="https://www.codewars.com/users/kylesalstrom">
                    <img className="content w-90p" src="https://www.codewars.com/users/kylesalstrom/badges/large?theme=dark" alt="codewars badge"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="tag-container">
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">C#</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">C++</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">Java</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">Python</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">Ruby</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">Go</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">PHP</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">HTML</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">CSS</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">JavaScript</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">TypeScript</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">React.js</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">Node.js</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">SQL</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">NoSQL</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">Regular Expressions</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">Command Line</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">Windows</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">OS X</div>
              <div className="tag px-2 py-1 bg-black text-white text-md font-bold uppercase">Linux</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stats;