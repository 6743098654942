import headshot from '../images/KyleAndHudson.jpg';

const Landing = () => {
  return (
    <section>
      <div className="hero">
        <div className="hero-body">
          <div className="content px-12-lg px-24-xl">
            <div className="u-flex u-items-center u-gap-4 u-flex-column u-gap-8-lg u-flex-row-reverse-md">
                <img className="u-round-xs w-90p-sm w-50p-md u-shadow-lg" src={headshot} alt="Kyle" />
              <div>
                <h1 className="headline-6">Hey, I'm Kyle!</h1>
                <p className="lead">and my website is still under development. Check back soon for more content, better design, and improved functionality.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Landing;